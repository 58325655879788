import type { FileDto } from '@viastud/server/services/file_service'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

export const fileToFileDto = (file: File): FileDto => {
  const fileUrl = URL.createObjectURL(file)

  const uuid = uuidv4()

  return {
    id: uuid,
    name: file.name,
    url: fileUrl,
  }
}

export async function fileDtoToFile(fileDto: FileDto) {
  const response = await axios.get<Blob>(fileDto.url, {
    responseType: 'blob',
  })

  const mimeType = response.headers['content-type']

  const file = new File([response.data], fileDto.name, { type: mimeType })

  return file
}
