import type * as React from 'react'

import { cn } from '#lib/utils'

export interface TextareaProps extends React.ComponentProps<'textarea'> {
  error?: boolean
}

const Textarea = ({ ref, className, error, ...props }: TextareaProps) => {
  return (
    <textarea
      className={cn(
        'flex min-h-[60px] w-full rounded-2xl border border-neutral-200 bg-transparent px-3 py-2 text-base shadow-sm placeholder:text-neutral-500 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-950 disabled:cursor-not-allowed disabled:opacity-50 md:text-sm dark:border-neutral-800 dark:placeholder:text-neutral-400 dark:focus-visible:ring-neutral-300',
        {
          'border-red-500 focus-visible:ring-red-500': error,
        },
        className
      )}
      ref={ref}
      {...props}
    />
  )
}
Textarea.displayName = 'Textarea'

export { Textarea }
