import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { Button } from '@viastud/ui/button'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@viastud/ui/carousel'
import { isCourseNowFromDateString } from '@viastud/ui/lib/reservations.utils'
import { trpc } from '@viastud/ui/lib/trpc'
import { cn } from '@viastud/ui/lib/utils'
import { SlotCard } from '@viastud/ui/shared/slot-card'

import { FillMonthlyAvailabilitiesModal } from '@/components/fill-monthly-availabilities-modal'

export const Route = createFileRoute('/_auth/')({
  component: Dashboard,
})

function Dashboard() {
  const navigate = useNavigate()
  const { data: incomingCourses } = trpc.reservations.getProfessorSlots.useQuery({
    isAfterNow: true,
  })

  const { data: previousCourses } = trpc.reservations.getProfessorSlots.useQuery({
    isAfterNow: false,
  })

  return (
    <div className="w-4/5">
      <FillMonthlyAvailabilitiesModal />
      {incomingCourses?.length !== 0 && (
        <>
          <div className="flex flex-row items-center justify-between gap-4 rounded-2xl p-6">
            <div className="text-2xl font-bold">Prochaines séances</div>
          </div>
          <Carousel>
            <CarouselContent>
              {incomingCourses?.map((course) => (
                <CarouselItem key={course.id} className="basis-1/3">
                  <SlotCard
                    key={course.id}
                    date={course.date}
                    courseName={course.courseName}
                    courseSubject={course.courseSubject}
                    courseGrade={course.courseGrade}
                    courseLevel={course.courseLevel}
                  >
                    {course.isEmpty ? (
                      <Button
                        variant="default"
                        className={cn('w-full bg-blue-600 text-white hover:bg-blue-700')}
                        disabled
                      >
                        En attente de reservation
                      </Button>
                    ) : isCourseNowFromDateString(course.date.fullDate) ? (
                      <Button
                        variant="default"
                        className={cn('w-full bg-blue-600 text-white hover:bg-blue-700')}
                        onClick={() => navigate({ to: `/meeting/${course.id}` })}
                      >
                        Démarrer le cours
                      </Button>
                    ) : (
                      <Button variant="outline" disabled className="w-full">
                        Bientôt
                      </Button>
                    )}
                  </SlotCard>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </>
      )}
      {previousCourses?.length !== 0 && (
        <>
          <div className="flex flex-row items-center justify-between gap-4 rounded-2xl p-6">
            <div className="text-2xl font-bold">Séances passées</div>
          </div>
          <Carousel>
            <CarouselContent>
              {previousCourses?.map((course) => (
                <CarouselItem key={course.id} className="basis-1/3">
                  <SlotCard
                    key={course.id}
                    date={course.date}
                    courseName={course.courseName}
                    courseSubject={course.courseSubject}
                    courseGrade={course.courseGrade}
                    courseLevel={course.courseLevel}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </>
      )}
    </div>
  )
}
