import { z } from 'zod';
export const parentDetailsSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z.string().nullable(),
    children: z.array(z.object({
        firstName: z.string(),
        lastName: z.string(),
        phoneNumber: z.string(),
        email: z.string(),
    })),
});
