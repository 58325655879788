import { z } from 'zod';
export const editProfileSchema = z.object({
    id: z.string().uuid(),
    lastName: z.string().min(1, {
        message: 'Le nom est requis',
    }),
    firstName: z.string().min(1, {
        message: 'Le prénom est requis',
    }),
    email: z
        .string()
        .min(1, {
        message: "L'adresse e-mail est requise",
    })
        .email({ message: "Le format de l'adresse mail est invalide" }),
    phoneNumber: z.string().min(1, {
        message: 'Le numéro de téléphone est requis',
    }),
});
